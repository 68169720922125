#blocker {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

#instructions {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
  font-size: 14px;
  cursor: pointer;
}

#crosshair {
  position: fixed;
  pointer-events: none;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
}

#crosshairImage {
  width: 1.5%;
  opacity: 0.8;
  object-fit: cover;
}

.top-bar {
  background: #181818;
  width: 100%;
  height: 75px;
  position: absolute;
  top: 0;
  z-index: 2;
  min-height: 50px;
  display: inline-block;

  .elements {
    list-style-type: none;
    display: inline-block;
    position: absolute;
    width: 100%;
    margin-left: 30px;
    color: white;

    .boids-dialog {
      max-width: 100px;
    }

    .controls {
      min-width: 100px;
    }

    li {
      float: left;
      margin-right: 15px;

      .slider-label {
        padding-left: 5px;
        float: right;
      }

      .slider {
        margin-top: 10px;
        min-width: 100px;
        margin: auto;
        color: #4d4d4e;
      }

      p {
        position: relative;
        display: block;
      }

      .switch {
        margin: auto;
        position: relative;
        display: inline-block;
        width: 35px;
        height: 17px;
        margin-bottom: 4px;

        input {
          opacity: 0;
          width: 0;
          height: 0;
        }
        .toggle-slider {
          position: absolute;
          cursor: pointer;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: #2196f3;
          -webkit-transition: 0.1s;
          transition: 0.1s;
          border-radius: 34px;

          &:before {
            position: absolute;
            content: "";
            height: 10px;
            width: 10px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: 0.1s;
            transition: 0.1s;
            border-radius: 34px;
          }
        }

        input:checked + .toggle-slider {
          background-color: #ff0000;
        }
        input:focus + .toggle-slider {
          box-shadow: 0 0 1px #ff0000;
        }
        input:checked + .toggle-slider:before {
          -webkit-transform: translateX(16px);
          -ms-transform: translateX(16px);
          transform: translateX(16px);
        }
      }
    }
  }
}
