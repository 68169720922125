@import "~react-mosaic-component/react-mosaic-component.css";
/* @import "~@blueprintjs/core/lib/css/blueprint.css"; */
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

.AppC {
  /* 60px's needed for sidebar spacing */
  width: 100vw - 60px;
  margin-left: 60px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Open Sans, Helvetica Neue, blueprint-icons-16, sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 1.2858142857;
  color: white;
}

.mosaic-window-body {
  background: #2f343c !important;
}
