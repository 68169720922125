.Tree {
  width: 100%;
  height: 100%;
}

.Tree .link {
  fill: none;
  stroke: black;
}

.Tree .node > rect {
  stroke: black;
  fill: var(--hovered-bg, #d3d3d3);
}

.Tree .node > text {
  text-anchor: middle;
  font-family: monospace;
  pointer-events: none;
}
