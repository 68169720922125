.warning-div {
  position: absolute;
  top: 50%;
  left: 40%;
  margin-left: 40px;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.warning-text {
  font-size: 16px;
  color: #fff;
  font-family: sans-serif;
  font-weight: 300;
  min-width: fit-content;
  margin-bottom: 1em;
  max-width: 500px;
}

.warning-button {
  background: #022c43;
  color: #ffd700;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: none;
  padding: 10px 18px;
  border: 1px solid #ffd700;
  margin-top: 25px;
  float: left;
  white-space: nowrap;

  &:hover {
    background: #ffd700;
    color: #333;
    cursor: pointer;
  }
}
