html {
  font-size: 16px;
}

body {
  margin: 0;
  font: 400 11px/1.4 "Helvetica Neue", sans-serif;
  color: #444;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #022c43;
  overflow: hidden;
  display: block;
}
