$item-count: 18;
$circle-size: 450px;
$item-size: 60px;
$angle: calc(360 / $item-count);

.logo-container {
  padding: 0;
  border-radius: 50%;
  list-style: none;
  z-index: 0;
  width: $circle-size;
  height: $circle-size;
  position: absolute;
  top: 0;
  right: 15%;
  bottom: 0;
  left: auto;
  margin: auto;

  > * {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $item-size;
    height: $item-size;
    margin: calc(-1 * ($item-size / 2));
    cursor: pointer;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        @if $i % 2 != 0 {
          filter: invert(77%)
            sepia(54%)
            saturate(1126%)
            hue-rotate(360deg)
            brightness(104%)
            contrast(104%);
        } @else {
          filter: invert(21%)
            sepia(30%)
            saturate(4086%)
            hue-rotate(178deg)
            brightness(90%)
            contrast(87%);
        }
      }
    }

    transform: translate(calc(var(--1) * 2px), calc(var(--1) * 2px));

    &:hover {
      transform: translate(calc(var(--1) * 2px), calc(var(--1) * 2px - 3px));
    }
  }
}

@keyframes anim {
  50% {
    transform: rotate(calc($angle * var(--1) * 1deg))
      translate(calc($circle-size / 2)) rotate(calc($angle * var(--1) * -1deg));
    rotate: 360deg;
  }
}

.logo-container[ani="1"] {
  > * {
    animation: anim 6s;
  }
}

.svg-container {
  stroke: #ffd700;
  stroke-width: 10;
}
