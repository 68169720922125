.TextPanel {
  overflow-wrap: break-word;
  font-family: monospace;
  cursor: default;
}
.TextPanel > div {
  display: inline-block;
}

.TextPanel {
  height: 100%;
  overflow-y: scroll;
}
.TextPanel > div {
  background-color: var(--hovered-bg, transparent);
}
