table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}
.StepsPanel {
  height: 100%;
  overflow-y: scroll;
  font-family: monospace;
  cursor: default;
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
}
.row {
  width: 100%;
  margin-left: -5px;
  margin-right: -5px;
}

.column {
  float: left;
  min-width: 100px;
  padding: 5px;
}

.huff-button {
  color-scheme: dark;
}

/* Create a two-column layout */
li > button {
  /* float: right; */
  text-align: right;
  /* width: 50%; */
  margin: 5px;
}

li > button:selection {
  background: rgba(125, 188, 255, 0.6);
}

/* Clearfix (clear floats) */
li > button::after {
  content: "";
  clear: both;
  /* display: table; */
}
/* 
tr:nth-child(even) {
  background-color: #dddddd;
} */

/* color hack from https://stackoverflow.com/questions/22252472/how-to-change-the-color-of-an-svg-element */
.github-icon {
  filter: invert(49%) sepia(0%) saturate(0%) hue-rotate(32deg) brightness(98%)
    contrast(95%);
}
